.licenseAgreementHeader {
  color: #262626;
  font-family: "Intel One Regular";
  font-size: 20px;
  font-weight: bold;
  padding-left: 61px;
  padding-top: 0;
  padding-bottom: 20px;
  padding-right: 92px;
  display: flex;
}

.licenseAgreementWrapper {
  background: #ffff !important;
  margin: 0px 52px 0px 52px;
  height: 500px;
  position: relative;
  overflow-y: auto;
  font-family: "Intel Clear";
  overflow: hidden;
  color: #262626;
}

.gradientBottom {
  position: absolute;
  width: 100%;
  height: 35px;
  bottom: 0;
  border-bottom: 1px dashed #548fad;
}

.gradientTop {
  position: absolute;
  width: 100%;
  height: 9px;
  top: 0;
  background: transparent linear-gradient(0deg, #ffffff00 0%, #fffffff2 61%, #ffffff 100%) 0% 0% no-repeat padding-box;
}

.marginBottom70 {
  margin-bottom: 70px;
}

.margin200 {
  margin-left: 90px;
  margin-right: 92px;
  border-bottom: 1px dashed #548fad;
  overflow: auto;
  margin-top: 44px;
}

.licenceAgreement {
  color: #666660;
    font-family: "Intel Clear";
    font-size: 12px;
    line-height: 16px;
    margin-top: 28px;
    padding-bottom: 24px;
    align-items:flex-end;
    display: flex;
    width: 100%;
    margin-right: 92px;
    justify-content: flex-end;
}

.divTableRow {
  display: table-row;
}

.divTableCell, .divTableHead {
  display: table-cell;
  padding: 5px 8px 5px 0px;
  font-size: 12px;
  font-weight: bold;
}

.acceptBtn {
  color: #ffffff;
  font-family: "Intel Clear";
  font-size: 14px !important;
  line-height: 15px !important;
  text-align: center !important;
  width: 76px;
  height: 32px;
  background-color: #0068b5 !important;
  border-color: #0068b5 !important;
  justify-content: center;
  border-radius: 0px;
  font-weight: bold;
}

.acceptBtn::before {
  background: none;
  border: 4px solid #fff;
}

.acceptBtn:focus {
  background-color: #ccc;
  border: 4px solid #fff;
}

.acceptBtn:hover {
  background: #004a86 0% 0% no-repeat padding-box !important;
}

.declineBtn, .declineBtn:hover, .declineBtn:active {
  box-sizing: border-box !important;
  width: 76px;
  height: 32px;
  border: 1px solid #525252;
  font-family: "Intel Clear" !important;
  font-size: 14px !important;
  line-height: 15px !important;
  text-align: center;
  justify-content: center;
  color: #262626;
  border-radius: 0px;
  font-weight: bold;
}

.declineBtn:focus, .acceptBtn:focus {
  background-color: #0068b5;
  border: 4px solid #fff;
  width: 76px;
  height: 32px;
  position: relative;
  margin: 0 auto;
  box-shadow: none;
  color: #fff;
  border: 1px solid #0068b5;
  box-shadow: inset 0px 0px 0px 1px #fff;
}

.declineBtn:hover {
  background: #004a86 0% 0% no-repeat padding-box !important;
  color: #ffffff;
}

.AcceptButtonStyles {
  padding-right: 92px;
}

@media screen and (min-width: 1920px) {
  .licenseAgreementWrapper {
    height: 503px;
  }
}

@media screen and (max-width: 1366px) {
  .margin200 {
    margin-left: 22px;
    margin-right: 22px;
    border-bottom: 1px dashed #548fad;
  }
  .licenseAgreementHeader {
    padding-left: 54px;
    padding-right: 66px;
  }
  .licenseAgreementWrapper {
    margin: 0 32px;
  }
}

@media screen and (min-width: 1440px) {
  .licenseAgreementHeader {
    padding-left: 137px;
  }
  .margin200 {
    margin-left: 85px;
    margin-right: 85px;
  }
}

@media screen and (min-width: 1600px) {
  .licenseAgreementHeader {
    padding-left: 147px;
  }
  .margin200 {
    margin-left: 95px;
    margin-right: 95px;
  }
}

@media screen and (min-width: 1920px) {
  .margin200 {
    margin-left: 114px;
    margin-right: 114px;
    border-bottom: 1px dashed #548fad;
  }
  .licenseAgreementHeader {
    padding-left: 166px;
  }
  
}

@media screen and (min-width: 2560px) {
  .licenseAgreementHeader {
    padding-left: 362px;
  }
  .margin200 {
    margin-left: 310px;
    margin-right: 310px;
    border-bottom: 1px dashed #548fad;
  }
}

@media screen and (min-width: 3440px) {
  .licenseAgreementHeader {
    padding-left: 686px;
  }
  .margin200 {
    margin-left: 634px;
    margin-right: 634px;
    border-bottom: 1px dashed #548fad;
  }
}

.DocIcon {
  width: 40px;
  height: 40px;
  border-radius: 25px;
  margin-right: 21px;
}

.GradientBelowTitle {
  border-bottom: 1px dashed #548fad;
  position: absolute;
  width: 100%;
  height: 9px;
  top: 0;
}

.LicenseTitleText {
  width: 100%;
  border-bottom: 1px dashed #548fad;
}
