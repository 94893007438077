@import '../../sassStyles/main.scss';

.la-body {
    display: flex;
    padding: 2rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 2rem;
    align-self: stretch;

    .la-page-section {
        display: flex;
        height: 482px;
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
        align-self: stretch;
        width: 100%;

        .la-page-title {
            color: var(--Text-text-primary, #2B2C30);

            /* Regular/Title S (font-size-300) */
            font-family: "IntelOne Display";
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: 32px; /* 133.333% */
        }

        .la-page-body {
            display: flex;
            height: 438px;
            align-items: flex-start;
            flex-shrink: 0;
            align-self: stretch;
            overflow-y: auto;

            .la-page-body-card-area {
                display: flex;
                height: 438px;
                flex-direction: column;
                align-items: flex-start;
                flex: 1 0 0;

                .la-page-body-card-area-highlight-border {
                    height: 2px;
                    flex-shrink: 0;
                    align-self: stretch;
                    background: var(--Aqua-Aqua, #16CFB1);
                }

                .la-page-body-card-section {
                    display: flex;
                    height: 436px;
                    padding: 2rem;
                    align-items: flex-start;
                    gap: 16px;
                    flex-shrink: 0;
                    align-self: stretch;

                    .la-page-body-card-text-content {
                        display: flex;
                        align-items: flex-start;
                        gap: 16px;
                        flex: 1 0 0;

                        .la-page-body-card-title {
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            gap: 8px;
                            flex: 1 0 0;

                            .la-page-body-card-text {
                                align-self: stretch;
                                color: var(--Text-text-primary, #2B2C30);

                                /* Regular/Title XS | Subtitle L (font-size-200) */
                                font-family: "IntelOne Display";
                                font-size: 20px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 28px; /* 140% */
                            }
                        }
                    }
                }

            }
        }
        
    }

    .la-buttons-section {

        display: flex;
        justify-content: flex-end;
        align-items: flex-start;
        gap: 16px;
        align-self: stretch;

        .la-decline-button {
            display: flex;
            height: var(--Height, 40px);
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            background: var(--Transparent-Transparent, #FFF);
            background-blend-mode: multiply;

            .la-decline-button-content {
                display: flex;
                padding: var(--v-padding, 10px) var(--h-padding, 16px);
                align-items: center;
                gap: var(--Gap, 8px);
                border: 1px solid var(--Aqua-Aqua-Shade-1, #0F9B8F);

                .la-decline-button-text {
                    color: var(--Aqua-Aqua-Shade-1, #0F9B8F);

                    /* Button/Button Large */
                    font-family: "IntelOne Text";
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 20px; /* 125% */
                }
            }
            
        }

        .la-accept-button {
            display: flex;
            height: var(--Height, 40px);
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            background: var(--Aqua-Aqua-Shade-1, #0F9B8F);

            .la-accept-button-content {
                display: flex;
                padding: var(--v-padding, 10px) var(--h-padding, 16px);
                align-items: center;
                gap: var(--Gap, 8px);

                .la-accept-button-text {
                    color: var(--Text-text-inverse, #FFF);
                    /* Button/Button Large */
                    font-family: "IntelOne Text";
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 20px; /* 125% */
                }
            }
        }
    }
}

.cds--btn--secondary:hover {
    background-color: var(--cds-button-secondary-hover, var(--cds-spark-button-secondary-hover)) !important;
}

.la-page-body-card-text{
    tr td{
        border: 1px solid;
        font-family: 'intelOne Text';
    }
}