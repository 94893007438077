@use '@spark-design/carbon-theme';
@import '@spark-design/fonts/fonts.css';
@import '@spark-design/iconfont/dist.web/icons.css';
@import "./resources/css/it-mlaf-css-master/css/it-mlaf.css";
@import "./resources/css/common.css";
@import "../node_modules/bootstrap/dist/css/bootstrap.min.css";


[data-color-scheme-fix='tiber'] {
    /* //accent light */
    --spark-color-classic-blue: var(--spark-color-aqua-shade-1);
    --spark-color-classic-blue-shade1: var(--spark-color-aqua-shade-1);
    --spark-color-classic-blue-shade2: var(--spark-color-aqua-shade-2);
    --spark-color-classic-blue-tint1: var(--spark-color-aqua-tint-1);
    --spark-color-classic-blue-tint2: var(--spark-color-aqua-tint-2);
  
    /* //accent dark */
    --spark-color-energy-blue: var(--spark-color-electric-aqua);
    --spark-color-energy-blue-shade1: var(--spark-color-aqua-shade-1);
    --spark-color-energy-blue-shade2: var(--spark-color-aqua-shade-2);
    --spark-color-energy-blue-tint1: var(--spark-color-aqua-tint-1);
    --spark-color-energy-blue-tint2: var(--spark-color-aqua-tint-2);
  
    /* //grays */
    --spark-color-theme-light-gray50: var(--spark-color-theme-tiber-light-gray50);
    --spark-color-theme-light-gray75: var(--spark-color-theme-tiber-light-gray75);
    --spark-color-theme-light-gray100: var(--spark-color-theme-tiber-light-gray100);
    --spark-color-theme-light-gray200: var(--spark-color-theme-tiber-light-gray200);
    --spark-color-theme-light-gray300: var(--spark-color-theme-tiber-light-gray300);
    --spark-color-theme-light-gray400: var(--spark-color-theme-tiber-light-gray400);
    --spark-color-theme-light-gray500: var(--spark-color-theme-tiber-light-gray500);
    --spark-color-theme-light-gray600: var(--spark-color-theme-tiber-light-gray600);
    --spark-color-theme-light-gray700: var(--spark-color-theme-tiber-light-gray700);
    --spark-color-theme-light-gray800: var(--spark-color-theme-tiber-light-gray800);
    --spark-color-theme-light-gray900: var(--spark-color-theme-tiber-light-gray900);
    --spark-color-theme-dark-gray50: var(--spark-color-theme-tiber-dark-gray50);
    --spark-color-theme-dark-gray75: var(--spark-color-theme-tiber-dark-gray75);
    --spark-color-theme-dark-gray100: var(--spark-color-theme-tiber-dark-gray100);
    --spark-color-theme-dark-gray200: var(--spark-color-theme-tiber-dark-gray200);
    --spark-color-theme-dark-gray300: var(--spark-color-theme-tiber-dark-gray300);
    --spark-color-theme-dark-gray400: var(--spark-color-theme-tiber-dark-gray400);
    --spark-color-theme-dark-gray500: var(--spark-color-theme-tiber-dark-gray500);
    --spark-color-theme-dark-gray600: var(--spark-color-theme-tiber-dark-gray600);
    --spark-color-theme-dark-gray700: var(--spark-color-theme-tiber-dark-gray700);
    --spark-color-theme-dark-gray800: var(--spark-color-theme-tiber-dark-gray800);
    --spark-color-theme-dark-gray900: var(--spark-color-theme-tiber-dark-gray900);
  }
