@import '../../sassStyles/main.scss';

.card-wrapper {
    clear: both;
    /* border: 1px solid #0071c5; */
    /* padding: 16px 85px 16px 32px;
    margin-top: 14px;
    box-sizing: border-box;
    position: relative; */
    /* background-color: #FFFFFF; */
    background: var(--Layer-layer-01, #F9F9F9);
    display: flex;
    padding: 16px;
    align-items: flex-start;
    gap: 16px;
    flex: 1 0 0;
    width: 100%;
    flex-direction: column;
}

.card-wrapper.selected {
    /* background-color: #effbff;
    border: 1px solid #0071C5; */

    border: 1px solid var(--Aqua-Aqua-Shade-1, #0F9B8F);
    background: rgba(162, 246, 232, 0.15);
}

.card-wrapper.disabled {
    /* border: 1px solid #999999;
    background-color: rgba(247, 248, 250, 0.45); */

    border: 1px solid var(--Border-border-subtle-selected-03, #C6C6C6);
    background: var(--Layer-layer-01, #F9F9F9);
}

.download-card-wrapper {
    clear: both;
    margin-top: 18px;
    box-sizing: border-box;
    position: relative;
}

.card-wrapper p {
    clear: both;
}

.card-wrapper h4 {
    font-size: 14px;
    margin: 0;
    color:#555555;
    font-weight: bold;
}

.infoIconBox {
    clear: both;
    position: absolute;
    right: 45px;
}

.cbox {
    clear: both;
    position: absolute;
    right: 36px;
    /* bottom: 10px; */
    margin-top: 60px;
}

.cbox input {
    margin: 0px;
    /* width: 20px;
    height: 20px; */
    width: 16px;
    height: 16px;
}

.downloadCard .card-main-wrapper {
    padding: 28px 24px;
}

.my-card-footer {
    position: absolute;
    align-content: center;
    bottom: 0;
}

.card-carousel {
    justify-content: center;
    position: relative;
    display: inline-flex;
    flex-direction: row;
    overflow-y: hidden;
}

.card-carousel .my-card {
    height: 100%;
    width: auto;
    position: relative;
    z-index: 1;
    background-color: #ffffff;
    flex-direction: row;
    flex-wrap: wrap;
    filter: grayscale(100%);
    top: 30px;    
    /* border-top: 4px solid #00AEEF !important; */
    border-top: 2px solid var(--Aqua-Aqua, #16CFB1) !important;
    opacity: 0.5;
}

.card-carousel .my-card:after {
    position: absolute;
    height: 2px;
    width: 100%;
    border-radius: 100%;
    bottom: -5rem;
}

.card-carousel .my-card:before {
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 3rem;
    font-weight: 300;
    color: #fff;
}

.card-carousel .my-card.active {
    z-index: 3;
    opacity: 1;
    pointer-events: auto;
    filter: none;
    background-color: #fff;
}

.card-carousel .my-card .row {
    min-width: 100%;
    margin: 0;
}

.sub-head {
    text-align: left;
    margin: 0px;
    width: 360px;
    color: #0071c5;
    font-family: "Intel Clear Pro";
    font-size: 21px;
    font-weight: bold;
    letter-spacing: 0;
}

.btnWrapper {
    display: flex;
    padding-left: 8px;
    gap: 8px;
}

.btnback {
    /* border: 1px solid #262626 !important;
    border-radius: 2px;
    font-size: 12px; */
    /* width: 48px;
    height: 24px; */
    /* background: #fff;
    color: #525252; */
    /* margin: 0 10px; */
    /* padding: 1px 6px; */

    background: var(--Transparent-Transparent, #FFF);
    background-blend-mode: multiply;
    border: 1px solid var(--Aqua-Aqua-Shade-1, #0F9B8F);

    color: var(--Aqua-Aqua-Shade-1, #0F9B8F);

    /* Button/Button Small */
    font-family: "IntelOne Text";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 12px; /* 100% */
    
}

.btnback:hover{
    /* background: #004a86 0% 0% no-repeat padding-box !important;
    color: #ffffff; */
    background-color: var(--cds-button-secondary-hover, var(--cds-spark-button-secondary-hover));
}
  .btnback:focus,
  .btnnext:focus {
    background-color: #0068b5;
    border: 4px solid #fff;
    position: relative;
    box-shadow: none;
    color: #fff;
    border: 1px solid #0068b5;
    box-shadow: inset 0px 0px 0px 1px #fff;
    
  }
.btnnext {
    /* border: 1px solid #00a3f6;
    background-color: #00a3f6;
    border-radius: 2px;
    font-size: 12px; */
    /* width: 48px;
    height: 24px; */
    /* color: #fff; */
    /* position: relative;
    top: -1px;
    padding: 1px 6px; */

    background: var(--Aqua-Aqua-Shade-1, #0F9B8F);
    color: var(--Text-text-inverse, #FFF);

    /* Button/Button Small */
    font-family: "IntelOne Text";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 12px; /* 100% */
}
  .btnnext span {
    position: relative;
    left: -5px;
  }
.btnnext:hover{
    /* background: #004a86 0% 0% no-repeat padding-box !important;
    color: #ffffff; */

    background-color: var(--cds-button-primary-hover, var(--cds-spark-button-primary-hover));
}

.pageIdentification {
    height: 16px;
    width: 49px;
    /* color: #252525;
    font-family: "Intel Clear";
    font-size: 12px;
    letter-spacing: 0;
    line-height: 16px; */
    text-align: right;
    margin-top: 6px;

    color: #000;

    /* keyboardshortcut/Small */
    font-family: "IntelOne Text";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
}

.pageIdentificationNew {
    height: 16px;
    width: 49px;
    /* color: #252525;
    font-family: "Intel Clear";
    font-size: 12px;
    letter-spacing: 0;
    line-height: 16px; */
    text-align: right;
    margin-top: 6px;
    padding-right: 15px;

    color: #000;

    /* keyboardshortcut/Small */
    font-family: "IntelOne Text";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
}

.my-card .ingredientContainer {
    padding: 0px 30px;
    overflow:hidden;
    /* height: 100% !important; */
    padding-bottom: 60px;

    gap: 16px;
    display: flex;
    width: 100%;
   
    flex-direction: column;
    align-items: flex-start;
  }
  
  .my-card.active .ingredientContainer {  
    overflow-x: auto;
    overflow-y: auto;
  }
  
  .ingredientContainer::-webkit-scrollbar {
    width: 10px;
  }
  
  .ingredientContainer::-webkit-scrollbar-track {
    background-color: #ebebeb;
    -webkit-border-radius: 20px;
    border-radius: 10px;
  }
  
  .ingredientContainer::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: #6d6d6d;
  }
  .fontsizeForward {
    color: white;
    font-size: 8px !important;
    position: relative;
    top: -17px;
    left: 15px;
  }
  input[type="checkbox"]:checked {
    /* background: #0071c5; */
    background: var(--Aqua-Aqua-Shade-1, #0F9B8F) !important;
  }
  input[type="checkbox"]:checked:after {
    /* background-color: #0071c5;
    border: 1px solid #0071c5; */
    border-radius: 2px;

    background: var(--Aqua-Aqua-Shade-1, #0F9B8F) !important;
    border: 1px solid var(--Aqua-Aqua-Shade-1, #0F9B8F);
  }

  .last-back-button {
    padding-left: 8px !important;
  }

  .dialogOkButton{
    background: var(--Aqua-Aqua-Shade-1, #0F9B8F) !important;
    color: var(--Text-text-inverse, #FFF);
  }

  