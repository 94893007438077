@import '../../sassStyles/main.scss';
.hero-banner-text {
    flex: 1 0 0;
    color: var(--Black-and-White-white, #FFF);

    /* Regular/Title M (font-size-400) */
    font-family: "IntelOne Display";
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    line-height: 48px; /* 133.333% */
    width: 100%;
    height: auto;
}

.hero-banner-button {
    display: flex;
    width: auto;
    height: var(--Height, 40px);
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    background: --Transparent-Transparent;
    background-blend-mode: multiply;

    .hero-banner-button-content {
        display: flex;
        padding: var(--v-padding, 10px) var(--h-padding, 16px);
        align-items: center;
        gap: var(--Gap, 8px);
        align-self: stretch;
       
        width: 100%;
        height: auto;

        .hero-banner-button-content-text {
            color: var(--Background-background, #FFF);
            border: 1px solid var(--Background-background, #FFF);
            /* Button/Button Large */
            font-family: "IntelOne Text";
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px; /* 125% */

        }

        .hero-banner-button-icon-section {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            .hero-banner-button-icon {
                width: 16px;
                height: 16px;
                background: var(--Transparent-Transparent, #FFF);
                background-blend-mode: multiply;         
            }
            
        }
    }


}

// .cds--btn.cds--btn--secondary:hover:not(.cds--btn--disabled) {
//     --cds-text-on-color: var(--cds-spark-button-primary-hover) !important;
//     border-color: var(--cds-spark-button-primary-hover) !important;
//     background-color: #16CFB1 !important;
// }
.white-text {
    --cds-text-secondary: white;
    --cds-text-primary: white;
    --cds-spark-theme-field-01: transparent;
}
.editOption.cds--btn.cds--btn--secondary:hover:not(.cds--btn--disabled){
    --cds-text-on-color: #00377c !important;
    border-color: #00377c !important;
    background-color: #e9eaeb !important;
}

.stack-desc {
    padding-bottom: 15px !important;
}

.stack-edit {
    padding-bottom: 35px !important;
}
   